<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <div class="mb-6">
        <h2 class="font-semibold text-blue-900 text-2xl">Fund Transfer List</h2>
      </div>
      <div class="flex flex-col">
        <div
          class="flex justify-between items-center m-3 flex-grow-0 flex-shrink"
        >
          <div class="">
            <div class="flex time-filter">
              <span class="mr-4">From: </span>
              <input
                type="date"
                class="inline-block border-2 px-2 rounded-sm mr-2"
                name=""
                id=""
                v-model="dateData.start_date"
              />
              <input
                type="time"
                class="inline-block border-2 px-2 rounded-sm mr-2"
                name=""
                id=""
                v-model="dateData.startTime"
              />
            </div>
            <div class="flex time-filter mt-2">
              <span class="mr-9">To: </span>
              <input
                type="date"
                class="inline-block border-2 px-2 rounded-sm mr-2"
                name=""
                id=""
                v-model="dateData.end_date"
              />
              <input
                type="time"
                class="inline-block border-2 px-2 rounded-sm mr-2"
                name=""
                id=""
                v-model="dateData.endTime"
              />
            </div>
            <div class="flex mt-3">
              <button
                @click="fetchDatedRecords"
                class="block mr-2 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
              >
                Get Records
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end items-end">
        <button
          class="bg-blue-600 py-3 px-6 rounded-md text-white"
          @click="downloadCSV"
        >
          Download CSV
        </button>
      </div>
      <div class="flex flex-row mt-5">
        <div>
          <select
            v-model="searchFilter"
            class="mr-3 drop-shadow-2xl ring-2 outline-none ring-blue-500 bg-white py-3 px-2 rounded-md"
          >
            <option value="">Search By</option>
            <option value="business_id">Business ID</option>
            <option value="destination_bank">Bank</option>
            <option value="account_number">Account</option>
            <option value="reference">Transaction Reference</option>
            <option value="status">Status</option>
          </select>
        </div>
        <div>
          <select
            type="text"
            required
            v-model="bank_id"
            v-if="searchFilter === 'destination_bank'"
            class="mr-3 drop-shadow-2xl ring-2 outline-none ring-blue-500 bg-white py-3 px-2 rounded-md"
          >
            <option :value="null" selected>Select Bank</option>
            <option
              v-for="bank in banks"
              :value="bank.bank_code"
              :key="bank.bank_code"
            >
              {{ bank.name }}
            </option>
          </select>
        </div>
        <div class="float-right">
          <input
            type="text"
            v-if="searchFilter !== 'destination_bank'"
            class="inline-block mx-2 px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-3"
            name=""
            placeholder="Search business id or Transaction Ref"
            id=""
            v-model="search"
          />
        </div>
        <button
          class="bg-blue-600 py-2 px-6 rounded-md text-white text-sm"
          @click="searchRecords"
        >
          Search
        </button>
      </div>
      <div class="flex flex-row mt-5" style="max-height: 500px; overflow: auto">
        <table class="shadow-lg bg-white w-full">
          <thead>
            <th
              class="text-l p-3 text-left"
              colspan="17"
              style="background: #dee6f2"
            >
              Merchant Information
            </th>
            <tr style="background: #fff">
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Business ID
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Business Name
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Account Number
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Receipient's Name
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Wallet ID
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Transaction Date
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Amount
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Destination Bank
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Payment Reference
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Reversal Date
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Narration
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Transaction Status
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Reversal
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="cursor-pointer"
              v-for="(fundData, i) in filterDocuments"
              :key="i"
            >
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ fundData.BusinessId || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ fundData.BusinessName || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ fundData.AccountNumber || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ fundData.RecipientName || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ fundData.WalletID || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ fundData.DateTransferred || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ fundData.Amount || "N/A" }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ fundData.DestinationBank || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ fundData.TransactionReference || "N/A" }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ fundData.DateReversed || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ fundData.Narration || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  fundData.ResponseCode == "99"
                    ? "Internal Error"
                    : fundData.ResponseCode === "100"
                    ? "Pending"
                    : fundData.ResponseCode === "101"
                    ? "Failed"
                    : fundData.ResponseCode === "102"
                    ? "Pending"
                    : fundData.ResponseCode === "103"
                    ? "Reversal"
                    : fundData.ResponseCode === "00"
                    ? "Success"
                    : "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <button
                  @click="reverseTransaction(fundData.TransactionReference)"
                  class="bg-blue-900 p-3 text-white rounded"
                >
                  Resolve Tranx
                </button>
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <a
                  :href="fundData.receiptlink"
                  class="bg-blue-900 p-3 text-white rounded"
                  v-if="fundData.receiptlink"
                  target="_blank"
                  >Download Receipt</a
                >
                <span v-else class="text-red-600">Receipt is N/A</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          Next &raquo;
        </button>
      </div>
      <div
        v-if="alertDiv"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            class="bi bi-exclamation-triangle-fill text-green-500 mx-auto"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
            />
          </svg>
          {{ alertMessage }}
          <button
            @click="alertDiv = false"
            class="float-right uppercase shadow mx-auto hover:bg-red-600 bg-red-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded"
            style="width: inherit"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      dateData: null,
      tim: new Date().getTime(),
      filterBy: [],
      search: "",
      walletFilter: [],
      downloadLink: null,
      loading: false,
      viewModal: false,
      payload: [],
      fundsData: [],
      currentPage: 1,
      isDisabled: false,
      searchFilter: "",
      searchPayload: [],
      banks: [],
      bank_id: null,
      alertMessage: "",
      alertDiv: false,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_FUND_TRANSFER"]),
    filterDocuments() {
      if (this.GET_FUND_TRANSFER.status || this.GET_FUND_TRANSFER) {
        let y =
          this.GET_FUND_TRANSFER &&
          this.GET_FUND_TRANSFER.filter(
            // return this.fundsData.filter(
            (document) =>
              (document &&
                document.RecipientName.toLowerCase().includes(
                  this.search.toLowerCase()
                )) ||
              (document &&
                document.AccountNumber.toLowerCase().includes(
                  this.search.toLowerCase()
                )) ||
              (document &&
                document.BusinessName.toLowerCase().includes(
                  this.search.toLowerCase()
                )) ||
              (document &&
                document.Email.toLowerCase().includes(
                  this.search.toLowerCase()
                )) ||
              (document &&
                document.BusinessId.toLowerCase().includes(
                  this.search.toLowerCase()
                )) ||
              (document &&
                document.TransactionReference.toLowerCase().includes(
                  this.search.toLowerCase()
                ))
          );
        return y;
      } else {
        let y = [];
        return y;
      }

      // let k = y.sort(
      //   (a, b) =>
      //     a.DateTransferred.trim().toLowerCase() <
      //     b.DateTransferred.trim().toLowerCase()
      // );
    },
  },
  async created() {
    const d = new Date();

    this.dateData = {
      // start_date: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      start_date: new Date(d.setDate(d.getDate() - 1)).toJSON().split("T")[0],
      end_date: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
      // enddatetime: new Date().toJSON().split("T")[0]
    };

    this.payload = {
      start_date: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      end_date: new Date().toJSON().split("T")[0],
    };

    this.fetchRecords();
    this.fetchBankList();
  },
  methods: {
    ...mapActions([
      "FETCH_FUNDS_TRANSFER",
      "BANK_LIST",
      "DOWNLOAD_CSV_FUNDS_TRANSFER",
      "SEARCH_SINGLE_FUNDS_TRANSFER",
      "REVERSE_FUNDS_TRANSFER",
    ]),
    async fetchBankList() {
      let res = await this.$store.dispatch("BANK_LIST");
      this.banks = res.data.items;
      this.banks.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    },
    async downloadCSV() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "DOWNLOAD_CSV_FUNDS_TRANSFER",
          this.dateData
        );
        if (res.length > 0) {
          this.fundsData = res.data.items;
          this.isLoading = false;
          this.alertDiv = true;
          this.alertMessage = res.message;
        } else {
          this.isLoading = false;
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}

      this.isLoading = false;
    },
    async fetchRecords() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "FETCH_FUNDS_TRANSFER",
          this.dateData
        );
        if (res.length > 0 || res.status) {
          this.isLoading = false;
          // this.fundsData = res.data.items;
        } else {
          this.isLoading = false;
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}

      this.isLoading = false;
    },

    async fetchDatedRecords() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "FETCH_FUNDS_TRANSFER",
          this.dateData
        );
        if (res.length > 0) {
          this.fundsData = res.data.items;
          this.isLoading = false;
        } else {
          this.isLoading = false;
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}

      this.isLoading = false;
    },

    async fetchDatedCSVRecords() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "FETCH_FUNDS_TRANSFER",
          this.search
        );
        if (res.length > 0) {
          this.fundsData = res.data.items;
          this.isLoading = false;
        } else {
          this.isLoading = false;
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}

      this.isLoading = false;
    },

    async searchRecords() {
      this.isLoading = true;
      let searchData = this.search !== "" ? this.search : this.bank_id;
      console.log(searchData);
      this.searchPayload = {
        search: searchData,
        filter: this.searchFilter,
      };
      try {
        let res = await this.$store.dispatch(
          "SEARCH_SINGLE_FUNDS_TRANSFER",
          this.searchPayload
        );
        if (res.length > 0) {
          this.isLoading = false;
          // this.fundsData = res.data.items;
          // console.log(this.fundsData);
        } else {
          this.isLoading = false;
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }

      this.isLoading = false;
    },

    async reverseTransaction(transaction_referrence) {
      let res = confirm("Are you sure you want to reverse this transaction?");
      if (res === true) {
        this.isLoading = true;
        let data = { TransactionReference: transaction_referrence };
        try {
          let res = await this.$store.dispatch("REVERSE_FUNDS_TRANSFER", data);
          if (res.length > 0) {
            alert("Reverse was successful");
            this.$router.go();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}

        this.isLoading = false;
      }
    },

    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_FUNDS_TRANSFER",
        this.dateData,
        currPage - 1
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let payload = { date: this.dateData, page: currPage + 1 };
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_FUNDS_TRANSFER",
        payload
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      }
    },

    sortData() {
      console.log("Sorting things");
      // return this.GET_TERMINALS.data.sort(function(a, b) {
      //   return a.deliveryStateSort - b.deliveryStateSort;
      // });
      return this.GET_TERMINALS.data.sort(
        (a, b) =>
          a.businessName.trim().toLowerCase() >
          b.businessName.trim().toLowerCase()
      );
    },
  },
};
</script>
<style>
.text-blue {
  color: #253b95;
}
.bg-blue {
  background-color: #253b95;
}
</style>
